<template>
  <div>
    <v-img
      :src="src || require('@/assets/images/default-image.png')"
      @click="$emit('click')"
      contain
      :aspect-ratio="ratio"
      alt="Image"
      class="h-100"
      :lazy-src="require('@/assets/images/default-image.png')"
    >
      <template v-slot:placeholder>
        <v-row align="center" class="fill-height ma-0" justify="center">
          <v-progress-circular
            color="grey-lighten-5"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </template>
      <template v-slot:error>
        <v-img
          class="mx-auto"
          height="300"
          max-width="500"
          :src="require('@/assets/images/default-image.png')"
        ></v-img>
      </template>
    </v-img>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      required: true
    },
    ratio: {
      required: false,
      type: String,
      default: "1"
    }
  }
};
</script>

<style>
.ratio-img {
  aspect-ratio: 16 / 9;
}
</style>
