<template>
  <div class="div-input">
    <div class="display-only" v-if="disabled"></div>
    <div
      :id="'input-' + name"
      :class="[
        'input-custom relative',
        {
          error: eachValid != null ? eachValid.$error && isValidate : isValidate
        }
      ]"
    >
      <label v-if="textFloat" :class="className" :name="'label-' + name">
        {{ textFloat }} <span class="text-remark">{{ textRemark }}</span>

        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <input
        :class="['custom-input f-regular']"
        :type="type.toLowerCase()"
        :placeholder="placeholder"
        :name="name"
        :required="required"
        :value="value"
        :size="size"
        @input="$emit('update:value', $event.target.value)"
        @change="onDataChange"
        @keypress="$emit('onkeypress', $event)"
        @paste="$emit('onPaste', $event)"
        @keyup="$emit('onkeyup', $event.target.value)"
        ref="input"
        :maxlength="maxlength"
        :disabled="disabled"
        :oninput="oninput"
        :pattern="type.toLowerCase() == 'number' ? '[0-9]*' : false"
        :inputmode="type.toLowerCase() == 'number' ? 'numeric' : inputmode"
      />
      <slot></slot>
    </div>
    <span v-if="detail" class="text-desc">{{ detail }}</span>

    <template v-if="eachValid">
      <div v-if="eachValid.$error && v && v.$error">
        <span
          class="text-error"
          v-if="(v.required && v.required.$invalid) || !v.required"
        >
          {{ validateText || $t("default_input_validate") }}
        </span>

        <span
          class="text-error"
          v-else-if="(v.minLength && v.minLength.$invalid) || !v.minLength"
        >
          <span data-lang="validate_input_min_length">
            {{ $t("validate_input_min_length") }}
            &nbsp;
          </span>
          {{ eachValid.$each.$response.$errors[index].value[0].$params.min }}
          <span data-lang="character">&nbsp;{{ $t("character") }} </span>
        </span>

        <span
          class="text-error"
          v-else-if="v.email && v.email.$invalid"
          data-lang="validate_email"
          >{{ $t("validate_email") }}
        </span>
        <span
          class="text-error"
          data-lang="validate_input_number"
          v-else-if="
            (v.integer && v.integer.$invalid) ||
            (v.decimal && v.decimal.$invalid) ||
            (v.minValue && v.minValue.$invalid)
          "
        >
          {{ $t("validate_input_number") }}
        </span>
        <span
          class="text-error"
          v-else-if="(v.maxLength && v.maxLength.$invalid) || !v.maxLength"
        >
          <span data-lang="validate_input_max_length"
            >{{ $t("validate_input_max_length") }}&nbsp;</span
          >
          {{ eachValid.$each.$response.$errors[index].value[0].$params.max }}
          <span data-lang="character">&nbsp; {{ $t("character") }}</span>
        </span>

        <span
          class="text-error"
          v-else-if="v.alpha && v.alpha.$invalid"
          data-lang="validate_english_only"
        >
          {{ $t("validate_english_only") }}
        </span>
        <span
          class="text-error"
          v-else-if="v.alphaNumEng && v.alphaNumEng.$invalid"
          data-lang="validate_english_num_only"
          >{{ $t("validate_english_num_only") }}</span
        >
      </div>
    </template>
    <template v-else>
      <div v-if="v && v.$error">
        <span class="text-error" v-if="v.required && v.required.$invalid">
          {{ validateText || $t("default_input_validate") }}
        </span>

        <span
          class="text-error"
          v-else-if="v.minLength && v.minLength.$invalid"
        >
          <span data-lang="validate_input_min_length">
            {{ $t("validate_input_min_length") }}&nbsp;
          </span>
          {{ v.minLength.$params.min }}
          <span data-lang="character">&nbsp;{{ $t("character") }}</span>
        </span>
        <span
          class="text-error"
          v-else-if="v.Script_checkID && v.Script_checkID.$invalid"
          data-lang="validate_id_card"
          >{{ $t("validate_id_card") }}</span
        >

        <span
          class="text-error"
          v-else-if="v.email && v.email.$invalid"
          data-lang="validate_email"
          >{{ $t("validate_email") }}
        </span>
        <span
          class="text-error"
          v-else-if="
            (v.integer && v.integer.$invalid) ||
            (v.decimal && v.decimal.$invalid) ||
            (v.minValue && v.minValue.$invalid)
          "
          >{{ $t("validate_input_number") }}</span
        >
        <span class="text-error" v-else-if="v.maxLength && v.maxLength.$invalid"
          >{{ $t("validate_input_max_length") }} {{ v.maxLength.$params.max }}
          {{ $t("character") }}</span
        >
        <span class="text-error" v-else-if="v.alpha && v.alpha.$invalid">
          {{ $t("validate_english_only") }}
        </span>
        <span
          class="text-error"
          v-else-if="v.alphaNumEng && v.alphaNumEng.$invalid"
        >
          {{ $t("validate_english_num_only") }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: true,
      type: String
    },
    className: {
      required: false,
      type: String
    },
    placeholder: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String,
      default: "text"
    },
    required: {
      required: false,
      type: Boolean
    },
    detail: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    disabled: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    maxlength: {
      required: false,
      type: [Number, String, Boolean]
    },
    oninput: {
      required: false,
      type: String,
      default:
        "javascript: if (this.value.length > 255) this.value = this.value.slice(0, 255);"
    },
    inputmode: {
      required: false,
      type: String
    },
    textRemark: {
      required: false,
      type: String
    },
    validateText: {
      required: false
    },
    eachValid: {
      required: false,
      type: Object,
      default: null
    },
    index: {
      required: false,
      type: Number,
      default: 0
    }
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value, event);
    },
    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.input-custom {
  padding: 0px;
}
.input-custom > input:disabled {
  background-color: var(--disable-color);
  color: #575757;
  border-radius: 0.25rem;
}

.input-custom > input {
  // color: #575757;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 0.25rem;
  height: 45px;
  padding: 5px 10px;
}
.input-custom > input[size="lg"] {
  height: 45px;
}
.input-custom > input:focus {
  border: 1px solid var(--primary-color);
  border-radius: 0.25rem;
}
.input-custom.error > input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: rgba(22, 39, 74, 0.4);
  font-size: var(--text-sm);
}

.text-remark {
  font-size: var(--text-md);
  color: gray;
}
</style>
