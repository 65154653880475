<template>
  <div>
    <v-dialog
      v-model="showModal"
      ref="alertModal"
      hide-header
      hide-footer
      centered
      @hidden="hide"
    >
      <div class="text-center mb-4">
        <p v-if="text" v-html="text"></p>
        <p v-if="desc" v-html="desc"></p>
      </div>
      <!-- <div class="border-top p-2 text-center btn-confirm-popup mb-2">
        <div @click=""></div>
      </div> -->
      <c-btn
        v-if="redeemBtn"
        variant="primary-theme"
        block
        @click="$emit('redeem')"
      >
        ยืนยันแลกรับของรางวัล</c-btn
      >
      <!-- <div class="border-top p-2 text-center btn-secondary">
        <div >{{ closeText }}</div>
      </div> -->
      <c-btn variant="outline-secondary" block @click="hide">
        {{ closeText }}
      </c-btn>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    },
    desc: {
      required: false,
      type: String
    },
    closeText: {
      required: false,
      default: "ปิด"
    },
    redeemBtn: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
      this.$emit("show");
    },

    hide() {
      this.showModal = false;
      this.$emit("hide");
    }
  }
};
</script>

<style rel="scss" scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-text {
  margin-bottom: 0;

  font-weight: 600;
}
.modal-body {
  margin: auto;
  text-align: center;
}
.class-icon-black {
  width: 70px;
}
</style>
