<template>
  <div>
    <!-- <pdf src="nissin-term.pdf" @loaded="onPdfLoad" v-if="isPdf" :page="1"></pdf> -->
    <!-- <pdf src="page-2.pdf" @loaded="onPdfLoad" v-if="isPdf" :page="1"></pdf> -->
    <div class="bg-white">
      <div v-html="policy"></div>
    </div>
  </div>
</template>

<script>
// import pdf from "vue-pdf";
export default {
  components: {
    // pdf
  },
  props: {
    text: {
      required: false,
      type: String
    },
    isPdf: {
      required: false
    },
    brand: {
      required: false
    }
  },
  data() {
    return {
      policy: ""
    };
  },
  async created() {
    await this.getPolicy();
  },
  methods: {
    async getPolicy() {
      await this.$axios.get(`/api/v1/DSR/terms_condition`).then(async data => {
        if (data.result === 1) {
          this.policy = data.detail;
        }
      });
    },
    onPdfLoad() {}
  }
};
</script>

<style rel="scss" scoped>
.bg-white {
  background-color: #fff;
  color: #000;
}
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-text {
  color: #000;
  margin-bottom: 0;
  font-size: var(--text-xl);
  font-weight: 600;
}
.modal-body {
  margin: auto;
  text-align: center;
}
.class-icon-black {
  width: 70px;
}
@media (max-width: 767.98px) {
  .text-text {
    font-size: var(--text-xl);
  }
}
.scroll-policy {
  overflow-y: visible;
  overflow-x: hidden;
  height: 100vh;
}
.ft-14 {
  font-size: var(--text-md);
}
.ft-underline {
  text-decoration: underline;
}
::v-deep table {
  border-collapse: collapse;
  width: 100%;
}

::v-deep td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

::v-deep tr:nth-child(even) {
  background-color: #dddddd;
}
::v-deep .close {
  font-size: 3.5rem;
  right: 40px;
  top: 10px;
}
</style>
