<template>
  <div>
    <v-progress-circular
      :size="size"
      :width="width"
      color="theme-primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      required: false,
      default: 32,
      type: Number
    },
    width: {
      required: false,
      default: 3  ,
      type: Number
    }
  }
};
</script>

<style></style>
