<template>
  <div>
    <VueDatePicker
      :model-value="value"
      @update:modelValue="$emit('update:value', moment($event).format())"
      :locale="locale"
      :flow="
        type == 'datetime'
          ? ['calendar', 'time']
          : type == 'birthday'
          ? ['year', 'month', 'calendar']
          : ['calendar']
      "
      minutes-grid-increment="1"
      :enable-time-picker="type == 'datetime'"
      :enable-seconds="false"
      :format="format"
      :preview-format="format"
      ref="datepicker"
      :closeOnAutoApply="false"
      year-first
    >
      <template #year="{ value }"> {{ yearFormat(value) }} </template>
      <template #year-overlay-value="{ value }">
        {{ yearFormat(value) }}
      </template>
    </VueDatePicker>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    placeholder: {
      required: false,
      default: () => "Please Select Datetime"
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    type: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      fromDateMenu: false,
      year: this.$store.state.theme.year_format
    };
  },
  computed: {
    formatDate() {
      this.fromDateMenu = false;
      return this.value && this.$moment(this.value).format("YYYY-MM-DD");
    },
    locale() {
      if (this.year == 1) return "th";
      else "en-US";
    }
  },
  methods: {
    format(date) {
      if (this.type == "datetime") {
        return this.$moment(date).format("DD/MM/YYYY HH:mm");
      }
      return this.$moment(date).format("DD/MM/YYYY");
    },

    selectDate() {
      this.$refs.datepicker.selectDate();
    },
    yearFormat(value) {
      if (this.year == 1) return Number(value) + 543;
      else return Number(value);
    }
  }
};
</script>

<style>
.set-min-width {
  min-width: unset !important;
}
.dp__input {
  height: 45px;
}
</style>
