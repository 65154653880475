import {
  instance as axios,
  axios as $axios,
  instance
} from "../services/axios";
import VueCookies from "vue-cookies";
import { createStore } from "vuex";
import router from "../router";
import { liff } from "../main";

const store = createStore({
  state: {
    shortProfile: {
      picture: null,
      profile: null,
      email: "",
      first_name_th: "",
      last_name_th: "",
      first_name_en: "",
      last_name_en: "",
      nickname: "",
      telephone: "",
      birthday: "",
      gender: "",
      total_point: 0,
      home_address: "",
      town: "",
      alley: "",
      road: "",
      subdistrict: "",
      district: "",
      province: "",
      zip_code: "",
      is_ready: false,
      user_guid: ""
    },
    completeProfile: {},
    theme: {
      is_custom_privacy_link: "0",
      is_center_point: "0",
      consent_url_link: "",
      is_right_request_url: "0",
      right_request_url: "",
      is_term_and_con_url: "0",
      term_and_con_url: "",
      themeBackGround: "",
      themeBackgroundPage: "",
      themeDisableColor: "",
      themeLightPrimaryColor: "",
      line_display_code_countdown: "",
      line_display_right_request_link: "",
      line_display_logout_button: "",
      themeLogoHomePage: "",
      themeLogoLine: "",
      themeLogoLoginPage: "",
      themePrimaryColor: "",
      themePrintLogo: "",
      themeSecondaryColor: "",
      themeSolidColor: "",
      themeTransactionCodeBanner: "",
      themeTransactionCodeEarn: "",
      themeTransactionCodeError: ""
    },
    editPhonenumber: false,
    templateReady: false,
    count: 0,
    domain: "",
    province: [],
    languageList: [],
    socialAnswer: []
  },
  mutations: {
    setShortProfile: async function (state, val) {
      if (val) {
        state.shortProfile = val;
      } else {
        state.authenticate = false;
      }
    },
    setTheme: async function (state, val) {
      state.theme = val.result;
      state.domain = val.themeLogoDomain;
    },
    setEditPhoneNumber: function (state, val) {
      state.editPhonenumber = val;
    },
    setTemplateIsReady: function (state, val) {
      state.templateReady = val;
    },
    setProvince: function (state, val) {
      state.province = val;
    },
    setCount: function (state, val) {
      state.count = val;
    },
    setLanguageList: function (state, val) {
      state.languageList = val;
    },
    setSocialUser: function (state, val) {
      state.socialAnswer = val;
    }
  },
  actions: {
    getUserProfile: async ({ commit, dispatch, state }, flag) => {
      commit("setCount", state.count + 1);

      if (state.templateReady && state.shortProfile.user_guid && !flag) {
        return state.shortProfile;
      }
      if (state.count == 2 && state.shortProfile.user_guid)
        return state.shortProfile;
      return await axios(`/api/v1/user/GetUserProfile`)
        .then(async res => {
          if (res.result == 1) {
            if (!res.detail.user_guid) {
              let body = {
                SocialProvider: "line",
                SocialId: VueCookies.get("user_profile_token").userId
              };
              axios.post(`/api/v1/user/unpair`, body).then(async data => {
                if (data.result == 1) {
                  this.$cookies.remove("token");

                  this.$cookies.remove("hourglass_register_success");
                  this.$router.replace("/validatetelephone");
                } else {
                  VueCookies.remove("token");
                  router.replace({
                    path: "/validatetelephone"
                  });
                }
              });
              return false;
            }
            let bd = res.detail.birthday;
            if (new Date(bd).getYear() == -147) {
              res.detail.birthday = "";
              res.detail.birthday_day = "";
              res.detail.birthday_month = "";
              res.detail.birthday_year = "";
            }

            commit("setShortProfile", res.detail);

            return res;
          } else {
            commit("setShortProfile", false);
          }
        })
        .catch(async err => {
          if (err.status == 401) {
            const res = await dispatch("checkHasUser");
            if (res.result == 0) return router.push("validatetelephone");
            return false;
          }

          return false;
        });
    },
    checkHasUser: async ({ commit, dispatch }) => {
      try {
        return new Promise(async (resolve, reject) => {
          const response = await dispatch("login");
          resolve(response);
        }).catch(err => {
          reject(err);
        });
      } catch (error) {
        return reject(error);
      }
    },
    setCompleteProfile: async ({ commit, rootGetters, state }, payload) => {
      state.completeProfile = payload.detail;
    },
    refreshUserProfile: async ({ commit, rootGetters }) => {
      await axios({
        url: `/api/v1/user/GetUserProfile`,
        headers: rootGetters["api/headers"],
        method: "get"
      }).then(
        res => {
          if (res.result == 1) {
            commit("setShortProfile", res.detail);
          } else {
            commit("setShortProfile", false);
          }
        },
        error => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            console.log(code);
          }
        }
      );
    },
    login: async ({ state, commit }) => {
      try {
        let body = {
          SocialProvider: "line",
          SocialId: VueCookies.isKey("user_profile_token")
            ? VueCookies.get("user_profile_token").userId
            : profile.userId
        };

        const response = await axios.post(`/api/v1/user/LoginExternal`, body);

        if (response.result == 1) {
          VueCookies.set("token", response.detail.token);

          if (!response.detail.token) {
            resolve({
              result: 0
            });
          }
        } else {
          VueCookies.remove("hourglass_register_success");
          VueCookies.remove("token");
        }

        return response;
        // });
      } catch (error) {
        // return window.alert(error, "here4");
        return error;
      }
    },
    lineLiffLogin: async ({ dispatch }, endPoint) => {
      try {
        // if()
        liff.ready
          .then(async () => {
            if (liff.isLoggedIn()) {
              return await dispatch("getProfileFromLine");
            } else {
              liff.login({
                redirectUri: endPoint
              });
            }
          })
          .catch(err => console.log(err));
      } catch (error) {
        // return window.alert(error, "hree5");
        return error;
      }
    },
    getProfileFromLine: async ({ dispatch }) => {
      const profile = await liff.getProfile();
      VueCookies.set("user_profile_token", profile, 60 * 60 * 24 * 30);
      await dispatch("login", profile);
      return profile;
    },
    getLanguage: async ({ commit }) => {
      const resp = await instance("/api/v1/setting/display_language");
      commit("setLanguageList", resp.detail);
      return resp.detail;
    },
    getTheme: async ({ commit }) => {
      const resp = await this.axios("/api/v1/Setting/theme");
      commit("setTheme", resp.detail);
      return resp;
    },
    setReady: async ({ commit, state }) => {
      if (!state.templateReady) commit("setTemplateIsReady", true);
      // return resp;
    },
    getProvince: async ({ commit, state }) => {
      if (state.province.length == 0) {
        const resp = await $axios(
          `https://api-address-prod.d-dots.com/api/address/form/province`
        );
        commit("setProvince", resp);
        return resp;
      }
      return state.province;
    },
    getUserBind: async ({ commit, state }) => {
      var profile = null;
      if (!VueCookies.isKey("user_profile_token")) {
        try {
          // console.log(lineLiffLogin);
          if (window.Cypress)
            profile = await window.Cypress.liffMock.getProfile();
          else profile = await liff.getProfile();
          VueCookies.set("user_profile_token", profile, 60 * 60 * 24 * 30);
        } catch (err) {
          console.log("object");
        }
      }
      let body = {
        SocialProvider: "line",
        SocialId: VueCookies.isKey("user_profile_token")
          ? VueCookies.get("user_profile_token").userId
          : profile
          ? profile.userId
          : null
      };
      if (body.SocialId) {
        const resp = await axios.post(`/api/v1/user/CheckSocialBind`, body);
        return resp.result;
      }
      return 1;
    }
  }
});
export default store;
