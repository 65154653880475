<template>
  <template v-for="items of options" :key="'checkbox-' + items[valueField]">
    <v-checkbox
      v-model="checkBoxValue"
      :value="items[valueField]"
      :label="items[textField]"
      hide-details
      single-line
      :false-value="falseValue"
      @update:modelValue="$emit('update:value', $event)"
      :error="
        eachValid
          ? eachValid.$error && val.length == 0 && v.required
          : isValidate
      "
    ></v-checkbox>
  </template>

  <template v-if="eachValid != null">
    <template v-if="eachValid.$error && v.$error">
      <div>
        <small v-if="v.required && v.required.$invalid" class="text-error">
          {{ validateText }}
        </small>
      </div>
    </template>

    <template v-else-if="eachValid.$error && val.length == 0">
      <div>
        <small v-if="v.required" class="text-error">
          {{ validateText }}
        </small>
      </div>
    </template>
  </template>
  <template v-else>
    <template v-if="v.$error">
      <div class="mt-2">
        <small
          v-if="(v.required && v.required.$invalid) || !v.required"
          class="text-error"
        >
          {{ validateText }}
        </small>
      </div>
    </template>
  </template>
</template>

<script>
export default {
  props: {
    options: { required: false, default: () => [] },
    value: { required: true },
    valueField: {
      required: false,
      default: "name"
    },
    textField: {
      required: false,
      default: "name"
    },
    falseValue: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      default: () => {
        return {};
      }
    },
    validateText: {
      required: false
    },
    isValidate: {
      required: false
    },
    eachValid: {
      required: false,
      default: null
    }
  },
  data() {
    return {
      checkBoxValue: this.value || []
    };
  },
  watch: {
    value(val) {
      this.checkBoxValue = val;
    }
  },
  mounted() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      if (this.value) return;
      this.$nextTick(() => {
        let check = this.options.find(el => el.isDefault == 1);

        if (check) {
          this.$emit("update:value", check.name);
          // this.value = "";
        }
      });
    }
  }
};
</script>

<style></style>
